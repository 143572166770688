import React, { useEffect, useState } from 'react';
import { useParams, useLocation } from 'react-router-dom';
import LoadingSpinner from '../../components/Messages/LoadingSpinner';
import { get } from '../../utils/api';

const QuoteRedirect = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const { id, jwt_token, existing_client } = useParams();
  const location = useLocation();

  useEffect(() => {
    const fetchQuoteUuid = async () => {
      try {
        const response = await get(`/quotation/${id}/uuid`);
        if (!response.ok) {
          throw new Error('Failed to fetch quote UUID');
        }
        
        const { uuid } = await response.json();
        
        const newPath = location.pathname.replace(id, uuid);
        const clientPortalUrl = process.env.REACT_APP_CLIENT_PORTAL_URL;
        const redirectUrl = `${clientPortalUrl}${newPath}${location.search}`;
        
        window.location.href = redirectUrl;
      } catch (err) {
        setError(err.message);
        setIsLoading(false);
      }
    };

    fetchQuoteUuid();
  }, [id, location.pathname, location.search]);

  if (error) {
    return (
      <div>
        <h1>Error</h1>
        <p>{error}</p>
      </div>
    );
  }

  return (
    <LoadingSpinner text={"Loading Page..."} /> 
  );
};

export default QuoteRedirect;
