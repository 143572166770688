import React from 'react';
import styles from './Quotation.module.scss';

const RedirectToClientPortalNotice = () => {
    return (
        <div className={styles.redirectNoticeContainer}>
            <div>
                <h2>The Quotation Module as been moved to Client Portal.</h2>
                <a href={`${new URL('quotation', process.env.REACT_APP_CLIENT_PORTAL_URL).href}`}><button>Take me there</button></a>
            </div>
        </div>
    );
};
    
export default RedirectToClientPortalNotice;
